import React from "react"
import {Link} from "gatsby"

import Layout from "../components/layout"
import Slider from "../components/slider_work"
import Seo from "../components/seo"

   
const replaceValidation = e =>{
    let val = e.target.value
    //全角を半角に修正
    function han2Zen(str) {
        return str.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function(s) {
            return String.fromCharCode(s.charCodeAt(0) - 0xFEE0);
        });
    }
    let replaceVal = han2Zen(val);
    e.target.value = replaceVal
    //バリデーション
    Validation(e)
}

let validFlug = [0,0,0,0,0,0,0]
const Validation = e =>{
    let validationMessage = e.target.validationMessage
    let formName = e.target.name
    
    var i
    if(formName==="company"){
        i = 0
    }else if(formName==="department"){
        i = 1
    }else if(formName==="occupation"){
        i = 2
    }else if(formName==="position"){
        i = 3
    }else if(formName==="name"){
        i = 4
    }else if(formName==="mail"){
        i = 5
    }else if(formName==="tel"){
        i = 6
    }else if(formName==="text"){
        i = 7
    }
    if(validationMessage === ""){
        e.target.style.background="whitesmoke"
        validFlug[i] = 1
    }else{
        e.target.style.background="pink"
        validFlug[i] = 0
    }
    //送信ボタン
    let submitFlug = [1,1,1,1,1,1,1]
    let submit = document.getElementById("submit")
    if(validFlug.toString() === submitFlug.toString()){
        submit.style.filter="none"
        submit.style.pointerEvents="auto"
    }else{
        submit.style.filter="grayscale(100%)"
        submit.style.pointerEvents="none"
    }
}  
    
const kensakuHtml = ({data,location}) => (
	<Layout>
	  <Seo
		pagetitle="工程強化"
		pagedesc="製造工程IoT基盤"
		pagepath={location.pathname}
	  />
        <div className="container-fluid kensaku product-detail_2">
          <div className="row">
            <div className="top-view">
              <div className="top-img bg-img_cover" />
              <ul className="breadcrumb">
                <li><Link to={`/`}>TOP</Link></li>
                <li><Link to={`/product/`}>PRODUCT</Link></li>
                <li>工程強化</li>
              </ul>{/*.breadcrumb*/}
              <div className="h1_wrap">
                <h1>工程強化</h1>
              </div>{/*.h1_wrap*/}
              <div className="h1-lead">製造工程IoT基盤</div>
              <a href="#scroll" id="scroll-button">
                <div className="scroll-down">
                  <div>SCROLL</div><div />
                </div>
              </a>
            </div>{/*.top-view*/}
            <div id="scroll" className="solution slide_1 bg-img_cover black-filter">
              <div className="contents_wrap">
                <div className="product-title">
                  <div>SOLUTION</div><div>相談無料</div>
                </div>{/*.product-title*/}
                <h2 className="product-h2 underline">製造工程IoT基盤</h2><div />
                <div className="product-lead">
                  一つ一つの製造工程に合わせて、データ収集システムの構築からAIフィードバックシステムの構築をワンストップで実施。<br />
                  全ての製造工程を一元管理し、AIによる良否判定や予知保全を行うことで、不良品の削減から生産性の向上を図ります。
                </div>{/*.product-lead*/}
              </div>{/*.contents_wrap*/}
            </div>{/*.solution*/}
            <div className="future">
              <div className="bk-back">
                <div className="future-img bg-img" />
              </div>
              <div className="contents-box">
                <div className="contents-title">FUTURE</div>
                <h3 className="underline">一元管理・撲滅保全</h3><div />
                <div className="contents-comment">
                  <div className="comment-br">
                    本サービスは、製造工程からPLCデータ・センサデータ・帳票データなどを収集蓄積するDB を構築し、そのデータを自動学習するAI を導入します。
                  </div>
                  <div className="comment-br">
                    そうすることで、膨大かつ点在しているデータをまとめて一元管理、分析可能な状態にし、さらにAIが最適な指示を出すことで、「製造工程のデータを一元管理できていない」・「不良品撲滅・予知保全をしたい」という課題の改善へ繋がります。
                  </div>
                </div>
              </div>{/*.contents-box*/}
            </div>{/*.future*/}
            <div className="sample">
              <div className="bk-back d-block d-md-none"><div className="system-img bg-img" /></div>
              <div className="contents-box">
                <div className="contents-title">SAMPLE</div>
                <h3 className="underline">不良品率の改善へ</h3><div />
                <div className="contents-comment">
                  <div className="comment-br">
                    不良品率に悩まされている砂型鋳造事業所のケース。
                  </div>
                  <div className="comment-br">
                    製品が出来上がるまでの各工程から、センシング・PLC連携・帳票のデジタル化を行い、データ収集蓄積可能な状態へ作業環境の改善を実施。
                  </div>
                  <div className="comment-br">
                    さらにAIが不良品を出さない製造条件を判定して、現場に最適な指示値をフィードバックしています。
                  </div>
                </div>
              </div>{/*.contents-box*/}
              <div className="bk-back d-none d-md-inline-block"><div className="system-img bg-img" /></div>
            </div>{/*.sample*/}
            <div className="flow">
              <div className="contents-title">FLOW</div>
              <div className="contents-box">
                <h3 className="underline">ソリューションの流れ</h3><div />
                <div className="contents-comment">
                  ご相談は無料です。<br />
                  一つ一つの製造工程に最適なソリューションをお客様と伴走し提供させていただきます。<br />
                  まずはどうぞお気軽にお問い合わせください。
                </div>
                <ul className="flow-list">
                  <li><div>一</div><div>お問い合せ</div></li>
                  <li><div>二</div><div>ヒアリング</div></li>
                  <li><div>三</div><div>ご提案</div></li>
                  <li><div>四</div><div>ご発注</div></li>
                  <li><div>五</div><div>要件定義・設計</div></li>
                  <li><div>六</div><div>開発・テスト</div></li>
                  <li><div>七</div><div>現場導入</div></li>
                  <li><div>八</div><div>保守改善</div></li>
                </ul>
              </div>{/*.contents-box*/}
            </div>{/*.flow*/}
            <div className="work wide-slider">
              <div className="contents-title">WORK</div>
              <div className="contents-box">
                  <Slider />
              </div>{/*.contents-box*/}
            </div>{/*.work*/}
            <div className="download">
              <div className="contents-title">DOWNLOAD</div>
              <div className="contents-box">
                <h3 className="underline">製品カタログはこちら</h3><div />
                <div className="contents-comment">
                  費用や詳細についてご興味のある方は、下記必要事項をご記入の上、こちらから製品カタログのダウンロードをお願いいたします。<br />
                  ご相談・お問い合せは、コンタクトフォームよりどうぞお気軽にご連絡ください。
                  <div className="small">＊入力は全て必須項目となっております</div>
                </div>
                <div className="row">
                  <div className="col-12 col-lg-5 col-xl-6"><div className="dl-img bg-img" /></div>
                  <div className="col-12 col-lg-7 col-xl-6">
                    <form name="KENSAKU-DL" method="POST" data-netlify="true" action={`/kensaku_dl/`}>
                    <table>
                      <tbody>
                        <input type="hidden" name="form-name" value="SESSAKU-DL" />
                        <tr><th>会社名</th>
                            <td>
                                <input type="text" name="company" required
                                    placeholder="ZZZ株式会社"
                                    onBlur={Validation}
                                    onInput={Validation}
                                />
                            </td>
                        </tr>
                        <tr><th>部署名</th>
                            <td>
                                <input type="text" name="department" required
                                    placeholder="ZZZ部"
                                    onBlur={Validation}
                                    onInput={Validation}
                                />
                            </td>
                        </tr>
                        <tr><th>職種</th>
                            <td>
                                <input type="text" name="occupation" required
                                    placeholder="ZZZ" 
                                    onBlur={Validation}
                                    onInput={Validation}
                                />
                            </td>
                        </tr>
                        <tr><th>役職</th>
                            <td>
                                <input type="text" name="position" required
                                    placeholder="ZZZ"
                                    onBlur={Validation}
                                    onInput={Validation}
                                />
                            </td>
                        </tr>
                        <tr><th>氏名</th>
                            <td>
                                <input type="text" name="name" required
                                    placeholder="ZZ ZZZ"
                                    onBlur={Validation}
                                    onInput={Validation}
                                />
                            </td>
                        </tr>
                        <tr><th className="line-2" aria-label="勤務先メールアドレス"><div><span>勤務先</span><span>メールアドレス</span></div></th>
                            <td>
                                <input type="email" name="mail" required
                                    placeholder="ZZZ@mazin.tech" 
                                    onBlur={Validation}
                                    onInput={Validation}
                                />
                            </td>
                        </tr>
                        <tr><th>電話番号(携帯)</th>
                            <td>
                                <input type="tel" name="tel" required
                                    placeholder="00-0000-0000"
                                    onBlur={replaceValidation}
                                    onInput={replaceValidation}
                                />
                            </td>
                        </tr>
                        <tr>
                            <th>IoT導入予定時期</th>
                            <td>
                                <select name="timing" required>
                                  <option value="3ヶ月以内">3ヶ月以内</option>
                                  <option value="半年以内">半年以内</option>
                                  <option value="1年以内">1年以内</option>
                                  <option value="1年以上先">1年以上先</option>
                                  <option value="時期は未定だが、検討中">時期は未定だが、検討中</option>
                                  <option value="予定はない">予定はない</option>
                                </select>
                            </td>
                        </tr>
                      </tbody>
                    </table>
                    <div data-netlify-recaptcha="true"></div>
                    <div className="submit_wrap">
                        <button type="submit" id="submit" >ダウンロード</button>
                        <div className="small">このサイトはreCAPTCHAによって保護されており、<br />Googleのプライバシーポリシーと利用規約が適用されます。</div>
                    </div>
                </form> 
                  </div>
                </div>
              </div>{/*.contents-box*/}
            </div>{/*.download*/}
          </div>{/*.row*/}
        </div>{/*.container-fluid*/}

	
	  </Layout>

)

export default kensakuHtml